/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,900&display=swap');
* {
    box-sizing: border-box;
}
html, body { height: 100%; }
body { margin: 0; font-family: sans-serif; }


::ng-deep .mat-mdc-text-field-wrapper{
  background-color: #ffffff !important;
  border-radius: 12px 12px 12px 12px !important;
  border: 1px solid var(--grayscale-3, #CDCFCE) !important;
  }

  ::ng-deep .custom-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 12px 12px 0;
  }

  ::ng-deep .custom-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 12px 0 0 12px;
  width: 25px !important;
  }
